import React from 'react'
import PropTypes from 'prop-types'
import ProductTitle from '@/components/ProductTitle'
import ProductPrice from '@/components/ProductPrice'
import ProductSubtotal from '@/components/ProductSubtotal'
import PromoBanner from '@/components/PromoBanner'
import useDeviceType from '@/hooks/useDeviceType'
import { AddToCart } from '@/components/AddToCart'
import DeliveryAndSetup from '@/components/DeliveryAndSetup'
import WhatsIncludedBB from '@/components/WhatsIncludedBB'
import BundlesProductSelectors from '@/components/BundlesProductSelectors'
import YotpoReviewStars from '../YotpoReviewStars'
import { CLASSIC_STARTER_BUNDLE, SAATVA_CLASSIC } from '@/constants/product-codes'

const BundlesBuystack = ({
    productCode
}) => {
    const { isDesktop } = useDeviceType('desktop')

    return (
        <section id="buystack">
            <ProductTitle productCode={productCode} />
            { productCode === CLASSIC_STARTER_BUNDLE && <YotpoReviewStars 
                productCode={SAATVA_CLASSIC}
                enableEarlyReviewMessage={true}
                showBadge
                className={'u-marginTop--xs'}
            />
            }
            <ProductPrice productCode={productCode} />
            <WhatsIncludedBB productCode={productCode} />
            <BundlesProductSelectors productCode={productCode} />
            <ProductSubtotal productCode={productCode} />
            <AddToCart productCode={productCode} className="u-marginBottom u-marginTop" />
            <PromoBanner productCode={productCode}/>
            {!isDesktop && <DeliveryAndSetup productCode={productCode} />}
        </section>
    )
}

BundlesBuystack.propTypes = {
    productCode: PropTypes.string
}

export default BundlesBuystack
