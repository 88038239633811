import { PRODUCT_CODES, PRODUCT_COLLECTIONS } from '@/constants'

// List of default selected values used for selecting default products.
// These object keys correspond to the keys in the productConfig data in localStorage.
const DEFAULT_PRODUCT_CONFIG = {
    mattressSize: 'Queen',
    rugSize: '8\' x 10\'',
    bathSet: '3-piece Bath Towel Set',
    dogBedSize: 'Medium',
    nightstandSize: '22'
}

// Returns an object with the correct key/value pair based on the product type
const getUserDefinedAttributes = (productCode, productConfig = DEFAULT_PRODUCT_CONFIG) => {
    let userDefinedAttributes = {}

    switch (true) {
        case PRODUCT_COLLECTIONS.RUGS.includes(productCode):
            userDefinedAttributes = { size: productConfig?.rugSize }
            break
        case PRODUCT_COLLECTIONS.TOWELS.includes(productCode):
            userDefinedAttributes = { bathSet: productConfig?.bathSet }
            break
        case PRODUCT_CODES.DOG_BED === productCode:
            userDefinedAttributes = { size: productConfig?.dogBedSize }
            break
        case PRODUCT_COLLECTIONS.NIGHTSTANDS.includes(productCode):
            userDefinedAttributes = { size: productConfig?.nightstandSize }
            break
        default:
            userDefinedAttributes = { size: productConfig?.mattressSize }
    }

    return userDefinedAttributes
}

const getProductConfigValue = (product, productState) => {
    const productCode = product.parentSku
    if (product.size) {
        switch (true) {
            case productState?.subcategory?.toLowerCase() === 'rugs':
                return ['rugSize', product.size]
            case PRODUCT_COLLECTIONS.NIGHTSTANDS.includes(productCode):
                return ['nightstandSize', product.size]
            case productState?.subcategory?.toLowerCase() === 'towels':
                return ['bathSet', productState.bathSet]
            case productCode === PRODUCT_CODES.DOG_BED:
                return ['dogBedSize', product.size]
            default:
                return ['mattressSize', product.size]
        }
    }
    return ['', null]
}

export {
    DEFAULT_PRODUCT_CONFIG,
    getUserDefinedAttributes,
    getProductConfigValue
}
