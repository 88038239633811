import React from 'react'
import ProductCarouselWrapper from '@/components/ProductCarouselWrapper'
import GoodMorningAmericaMessage from '@/components/GoodMorningAmericaMessage'
import DeliveryAndSetup from '@/components/DeliveryAndSetup/DeliveryAndSetup'
import VideoBelowPDPCarousel from '@/components/VideoBelowPDPCarousel'
import NeedHelpSection from '@/components/NeedHelpSection'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import useDeviceType from '@/hooks/useDeviceType'
import { SAATVA_CLASSIC } from '@/constants/product-codes'

const CarouselPanel = ({ productCode }) => {
    const { isDesktop } = useDeviceType('desktop')
    const { isV1: phoneNumberTest } = useExperiment('EXP.NEED_HELP.EX-391')

    const isSaatvaClassicDesktop = isDesktop && productCode === SAATVA_CLASSIC

    return (
        <div className='col col--xs-12 col--md-12 col--lg-7 productPanelWrapper__leftPanel'>
            <ProductCarouselWrapper productCode={productCode} />

            {isDesktop ? (
                <>
                    <DeliveryAndSetup productCode={productCode} />
                    {isSaatvaClassicDesktop && (
                        <>
                            <GoodMorningAmericaMessage productCode={productCode} />
                            <VideoBelowPDPCarousel productCode={productCode} className='u-hidden--lg-down' />
                            {phoneNumberTest && <NeedHelpSection />}
                        </>
                    )}
                </>
            ) : (
                <GoodMorningAmericaMessage productCode={productCode} />
            )}
        </div>
    )
}

export default CarouselPanel
