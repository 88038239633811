import { PRODUCT_CODES } from '@/constants'
import * as productCodes from '../constants/product-codes'

const carouselData = {

    /* ---------  Furniture --------- */
    [PRODUCT_CODES.ADJUSTABLE_BASE]: {
        altTag: 'Saatva Adjustable Base',
        attributeSets: [], // empty array means no variation in imagery besides descriptor
        imageDescriptors: [
            // Currently, this product is set to show 5 images for each variant,
            // but this is accomplished by using 6 items and exclusions.
            'model',
            {
                descriptorName: 'angle',
                attributeSets: ['splitType']
            },
            {
                descriptorName: 'side',
                attributeSets: ['splitType']
            },
            {
                descriptorName: 'front',
                attributeSets: ['splitType'],
                exclusions: {
                    splitType: 'standard' // front image will not display for standard mattresses
                }
            },
            {
                descriptorName: 'silo',
                attributeSets: ['mattressSize'] // also follows order in array
            },
            'remote'
        ]
    },
    [PRODUCT_CODES.ADJUSTABLE_BASE_PLUS]: {
        altTag: 'Saatva Adjustable Base Plus',
        attributeSets: [],
        imageDescriptors: [
            'side', // defaults to just a descriptor string
            'couple',
            {
                descriptorName: 'silo',
                attributeSets: ['mattressSize'] // also follows order in array
            },
            'remote'
        ]
    },
    [productCodes.AMALFI]: {
        altTag: 'Amalfi',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.AMALFI_PLATFORM_BED]: {
        altTag: 'Amalfi Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.AMALFI_WITH_STORAGE]: {
        altTag: 'Amalfi Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.COPENHAGEN]: {
        altTag: 'Copenhagen',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.FOUNDATION]: {
        altTag: 'Saatva Mattress Foundation',
        attributeSets: [],
        imageDescriptors: [
            {
                descriptorName: 'front',
                attributeSets: ['mattressType', 'foundationHeight']
            },
            {
                descriptorName: 'angle',
                attributeSets: ['foundationHeight']
            },
            {
                descriptorName: 'side',
                attributeSets: []
            },
            {
                descriptorName: 'detail',
                attributeSets: []
            },
            {
                descriptorName: 'silo',
                attributeSets: ['mattressType', 'foundationHeight']
            }
        ]
    },
    [productCodes.HALLE]: {
        altTag: 'Halle',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.HALLE_STORAGE_PLATFORM_BED]: {
        altTag: 'Halle Storage Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.LUCERNE]: {
        altTag: 'Lucerne',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-front', 'room-angle', 'room-side', 'ar']
    },
    [productCodes.MARBELLA]: {
        altTag: 'Marbella',
        attributeSets: ['sidePanels', 'bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.MARSEILLE]: {
        altTag: 'Marseille',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.MINORI]: {
        altTag: 'Minori',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.MINORI_WITH_STORAGE]: {
        altTag: 'Minori',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.PORTO]: {
        altTag: 'Porto',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.SANTORINI]: {
        altTag: 'Santorini Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.RHODES]: {
        altTag: 'Rhodes Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.SANTORINI_PLATFORM_BED]: {
        altTag: 'Santorini Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.SIENA_LEATHER]: {
        altTag: 'Siena',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['front', 'angle', 'side', 'ar']
    },
    [productCodes.SYDNEY]: {
        altTag: 'Sydney',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.VALENCIA]: {
        altTag: 'Valencia',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side', 'ar']
    },
    [productCodes.HALLE_WITH_STORAGE]: {
        altTag: 'Halle with Storage',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.CASSIS]: {
        altTag: 'Cassis Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.CASSIS_WITH_STORAGE]: {
        altTag: 'Cassis Storage Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.MILAN]: {
        altTag: 'Milan Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.CALAIS]: {
        altTag: 'Calais Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.LYON]: {
        altTag: 'Lyon Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.MERANO]: {
        altTag: 'Merano Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.CRETE]: {
        altTag: 'Crete Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.ADRA_LEATHER]: {
        altTag: 'Adra Leather Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.LYON_WITH_STORAGE]: {
        altTag: 'Lyon Storage Bed ',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side',
            'ar'
        ]
    },
    [productCodes.COLOGNE_WOOD]: {
        altTag: 'Cologne Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.CORSICA_WOOD]: {
        altTag: 'Corsica Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.MATERA_WOOD]: {
        altTag: 'Matera Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'ar']
    },
    [productCodes.SCENTED_CANDLES]: {
        altTag: 'Scented Candles',
        attributeSets: [],
        imageDescriptors: [
            'silo-drift',
            'silo-restore',
            'silo-dream',
            'silo-unwind',
            'lifestyle'
        ]
    },
    [productCodes.SCENTED_VOTIVES]: {
        altTag: 'Scented Votives',
        attributeSets: [],
        imageDescriptors: ['silo-set', 'box-closed', 'lifestyle']
    },

    /* ---------  Rugs --------- */

    [productCodes.ADAMAS_BEDROOM_RUG]: {
        altTag: 'Adamas Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.CIRRUS_BEDROOM_RUG]: {
        altTag: 'Cirrus Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.KANAN_BEDROOM_RUG]: {
        altTag: 'Kanan Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.TERNA_BEDROOM_RUG]: {
        altTag: 'Terna Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.MYRA_BEDROOM_RUG]: {
        altTag: 'Myra Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.NAVI_BEDROOM_RUG]: {
        altTag: 'Navi Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },

    /* ---------  Chairs --------- */

    [productCodes.COMO_SWIVEL_CHAIR]: {
        altTag: 'Como Swivel Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            {
                descriptorName: 'room',
                attributeSets: []
            }
        ]
    },
    [productCodes.ANAIS_CHAIR]: {
        altTag: 'Anais Chair',
        attributeSets: ['chairOptions', 'bedFrameFabric'], // will likely be color in the new model
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room',
                attributeSets: []
            },
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
        ]
    },
    [productCodes.LUNA_SWIVEL_CHAIR]: {
        altTag: 'Luna Swivel Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room-fireplace',
                attributeSets: []
            },
            {
                descriptorName: 'room-window',
                attributeSets: []
            },
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
        ]
    },
    [productCodes.LOGAN_CHAIR]: {
        altTag: 'Logan Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room',
                attributeSets: []
            },
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
        ]
    },

    /* ---------  Benches --------- */

    [productCodes.CONSTANCE_TUFTED_OTTOMAN]: {
        altTag: 'Constance Tufted Ottoman',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'angle',
            'side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [productCodes.ARNO_CUSHIONED_BENCH]: {
        altTag: 'Arno Cushioned Bench',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'angle',
            'front',
            'side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [productCodes.BRIENNE_CHANNEL_OTTOMAN]: {
        altTag: 'Brienne Channel Ottoman',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'angle',
            'front',
            'side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [productCodes.LENA_CUSHIONED_BENCH]: {
        altTag: 'Lena',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'angle',
            'side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [productCodes.RHONE_STORAGE_BENCH]: {
        altTag: 'Rhone Storage Bench',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'open',
            'side',
            {
                isGeneric: true,
                descriptorName: 'closer-look-fabric',
                attributeSets: []
            },
            'silo-angle'
        ]
    },

    /* ---------  Lighting --------- */
    [productCodes.HUGO_LAMP]: {
        altTag: 'Hugo Lamp',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.NOVA_LAMP]: {
        altTag: 'Nova Lamp',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.SAVILE_LAMP]: {
        altTag: 'Savile Lamp',
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.CORSO_LAMP]: {
        altTag: 'Corso Lamp',
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.REGENT_LAMP]: {
        altTag: 'Regent Lamp',
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.SOFIA_LAMP]: {
        altTag: 'Sofia Lamp',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.LUCIEN_LAMP]: {
        altTag: 'Lucien Lamp',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },
    [productCodes.AREOS_LAMP]: {
        altTag: 'Areos Lamp',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle1',
            'lifestyle2',
            'lifestyle',
            'silo'
        ]
    },

    /* ---------  Mattresses --------- */

    [productCodes.SAATVA_CLASSIC]: {
        altTag: 'Saatva Classic Mattress',
        attributeSets: [], // TODO video was added here for test. If it wins/fails, it has to change for a better solution
        imageDescriptors: [
            'saatvana-angle',
            'video',
            'front',
            'layer-diagram',
            'lifestyle-bedroom',
            'detail',
            {
                descriptorName: 'above',
                attributeSets: ['mattressType']
            },
            'side'
        ]
    },
    [productCodes.SAATVA_HD]: {
        altTag: 'Saatva HD Mattress',
        attributeSets: [],
        imageDescriptors: [
            {
                descriptorName: 'front',
                attributeSets: ['mattressType']
            },
            'side',
            'angle',
            'saatvana',
            {
                descriptorName: 'above',
                attributeSets: ['mattressType']
            }
        ]
    },
    [productCodes.SOLAIRE]: {
        altTag: 'Solaire Adjustable Firmness Mattress',
        attributeSets: ['mattressType'],
        imageDescriptors: [
            'room-front',
            'room-angle-raised',
            'room-angle',
            {
                descriptorName: 'room-side',
                attributeSets: []
            },
            'room-above',
            {
                descriptorName: 'saatvana',
                attributeSets: []
            }
        ]
    },
    [productCodes.CRIB_MATTRESS]: {
        altTag: 'Crib Mattress',
        attributeSets: [],
        imageDescriptors: ['front', 'pillows', 'above', 'sweep', 'silo']
    },
    [productCodes.DOG_BED]: {
        altTag: 'Saatva Dog Bed',
        attributeSets: [],
        imageDescriptors: [
            {
                descriptorName: 'front-model',
                attributeSets: ['size']
            },
            {
                descriptorName: 'front',
                attributeSets: ['size', 'color']
            },
            {
                descriptorName: 'detail',
                attributeSets: ['color']
            },
            {
                descriptorName: 'colorway-front',
                attributeSets: []
            },
            {
                descriptorName: 'angle-model',
                attributeSets: ['size'],
                exclusions: {
                    size: ['small']
                }
            }
        ]
    },
    [productCodes.MEMORY_FOAM_HYBRID]: {
        altTag: 'Memory Foam Hybrid Mattress',
        attributeSets: [],
        imageDescriptors: ['angle', 'above', 'front', 'side', 'sweep', 'model-laying']
    },
    [productCodes.SAATVA_RX]: {
        altTag: 'Saatva Rx Mattress',
        attributeSets: [],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'above', 'silo-top', 'model']
    },
    [productCodes.SAATVA_YOUTH]: {
        altTag: 'Saatva Youth Mattress',
        attributeSets: [],
        imageDescriptors: ['front', 'detail', 'tween', 'girl', 'boy']
    },
    [productCodes.SAATVA_LATEX_HYBRID]: {
        altTag: 'Saatva Latex Hybrid Mattress',
        attributeSets: [],
        imageDescriptors: [
            'side',
            {
                descriptorName: 'angle',
                attributeSets: ['mattressType']
            },
            'saatvana-side',
            'saatvana-corner',
            {
                descriptorName: 'above',
                attributeSets: ['mattressType']
            },
            'front'
        ]
    },
    [productCodes.ZENHAVEN]: {
        altTag: 'Zenhaven Mattress',
        attributeSets: ['mattressType'],
        imageDescriptors: [
            'room-angle-raised',
            'side',
            'angle',
            {
                descriptorName: 'corner',
                attributeSets: []
            },
            {
                descriptorName: 'model',
                attributeSets: []
            },
            'above'
        ]
    },
    [productCodes.BUNK_TRUNDLE_YOUTH]: {
        altTag: 'Bunk & Trundle Mattress',
        attributeSets: [],
        imageDescriptors: [
            `room-front`,
            `room-angle`,
            `lifestyle-front`,
            `lifestyle-angle`,
            `silo-top`
        ]
    },
    [productCodes.LOOM_AND_LEAF]: {
        altTag: 'Loom & Leaf Mattress',
        attributeSets: [],
        imageDescriptors: [
            'side',
            {
                descriptorName: 'angle',
                attributeSets: ['mattressType']
            },
            'child',
            'model',
            {
                descriptorName: 'front',
                attributeSets: ['mattressType']
            },
            'couple'
        ]
    },
    [productCodes.SAATVA_CONTOUR5]: {
        altTag: 'Contour5 Mattress',
        attributeSets: [],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'room-above', 'sweep', 'side']
    },

    /* ---------  Bedding --------- */

    [productCodes.DOWN_ALTERNATIVE_PILLOW]: {
        altTag: 'Down Alternative Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            },
            'side',
            'detail',
            'corner'
        ]
    },
    [productCodes.THE_SAATVA_PILLOW]: {
        altTag: 'Saatva Latex Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'lifestyle-woman-sleeping',
            {
                descriptorName: 'side',
                attributeSets: ['pillowHeightOptions']
            },
            {
                descriptorName: 'detail',
                attributeSets: ['pillowHeightOptions']
            },
            'corner'
        ]
    },
    [productCodes.MEMORY_FOAM_PILLOW]: {
        altTag: 'Graphite Memory Foam Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            }
        ]
    },
    [productCodes.ORGANIC_COTTON_SHEETS]: {
        altTag: 'Organic Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [productCodes.ORGANIC_MATTRESS_PAD]: {
        altTag: 'Organic Mattress Pad',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-profile',
            'silo',
            'silo-folded'
        ]
    },
    [productCodes.WATERPROOF_MATTRESS_PROTECTOR]: {
        altTag: 'Waterproof Mattress Protector',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-corner',
            'silo',
            'silo-folded'
        ]
    },
    [productCodes.FEATHERBED_MATTRESS_TOPPER]: {
        altTag: 'Down Alternative Featherbed Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'silo-profile',
            'silo-close-up',
            'lifestyle-setup',
            'lifestyle-detail'
        ]
    },
    [productCodes.MICROCOIL_MATTRESS_TOPPER]: {
        altTag: 'Micro-Coil Mattress Topper',
        attributeSets: [],
        imageDescriptors: ['lifestyle-corner', 'lifestyle-side']
    },
    [productCodes.AERO_QUILT]: {
        altTag: 'Aero Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'room-angle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }
            },
            {
                descriptorName: 'bed-top',
                attributeSets: ['color'],
                exclusions: {
                    color: ['sage', 'sand'] // handling both possible names for backwards compatibility
                }
            },
            {
                descriptorName: 'messy',
                attributeSets: ['color'],
                exclusions: {
                    color: ['indigo', 'white']
                }
            },
            'silo',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }
            }
        ]
    },
    [productCodes.DOWN_ALTERNATIVE_COMFORTER]: {
        altTag: 'All-Year Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: [
            'room-front',
            'room-angle',
            'silo-top',
            'detail',
            'detail-corner',
            'silo'
        ]
    },
    [productCodes.LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER]: {
        altTag: 'Lightweight Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: [
            'room-front',
            'room-angle',
            'silo-top',
            'detail',
            'detail-corner',
            'silo'
        ]
    },
    [productCodes.HEAVYWEIGHT_COMFORTER]: {
        altTag: 'Heavyweight Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: [
            'room-front',
            'room-angle',
            'silo-top',
            'detail',
            'detail-corner',
            'silo'
        ]
    },
    [productCodes.CLOUD_MEMORY_FOAM_PILLOW]: {
        altTag: 'Cloud Memory Foam Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-white',
                attributeSets: []
            }
        ]
    },
    [productCodes.ORGANIC_QUILTED_PILLOW]: {
        altTag: 'Organic Quilted Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            },
            {
                isGeneric: true,
                descriptorName: 'pillows-on-white',
                attributeSets: []
            }
        ]
    },
    [productCodes.HERRINGBONE_KNIT_BLANKET]: {
        altTag: 'Herringbone Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'detail-angle',
            'messy',
            'room-angle',
            {
                descriptorName: 'colorways-stack',
                attributeSets: []
            },
            'silo'
        ]
    },
    [productCodes.WAFFLE_KNIT_BLANKET]: {
        altTag: 'Waffle Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'room-angle',
            'lifestyle-chair',
            'detail',
            'room-profile',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            'silo'
        ]
    },
    [productCodes.SWEATER_KNIT_BLANKET]: {
        altTag: 'Sweater Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'room-angle',
            'lifestyle-chair',
            'detail',
            'room-side',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            'silo'
        ]
    },
    [productCodes.WEIGHTED_BLANKET]: {
        altTag: 'Weighted Blanket',
        attributeSets: ['weightedBlanketColor'],
        imageDescriptors: [
            'on-sofa-draped',
            'model-sofa',
            'on-sofa-folded',
            'model-bed',
            {
                descriptorName: 'hanging',
                attributeSets: []
            }
        ]
    },
    [productCodes.DIAMOND_KNIT_BLANKET]: {
        altTag: 'Diamond Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'room-angle',
                attributeSets: [],
                exclusions: {
                    color: 'sand'
                }
            },
            {
                descriptorName: 'room-front',
                attributeSets: [],
                exclusions: {
                    color: ['white', 'ash']
                }
            },
            'silo',
            {
                descriptorName: 'colorways-texture',
                attributeSets: []
            },
            {
                descriptorName: 'detail',
                attributeSets: []
            },
            {
                descriptorName: 'colorways-stacked',
                attributeSets: []
            }
        ]
    },
    [productCodes.PERCALE_SHEET_SET]: {
        altTag: 'Percale Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [productCodes.ESSENTIAL_PILLOWCASES]: {
        altTag: 'Essential Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.LINEN_SHEET_SET]: {
        altTag: 'Linen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'detail-corner',
            'lifestyle'
        ]
    },
    [productCodes.EMBROIDERED_HOTEL_STYLE_SHEETS]: {
        altTag: 'Embroidered Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-close-up',
            'lifestyle-angle',
            'detail-corner',
            {
                descriptorName: 'colorways'
            },
            'lifestyle'
        ]
    },
    [productCodes.BANDED_PERCALE_SHEET_SET]: {
        altTag: 'Banded Percale Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle',
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: ['color'],
                exclusions: {
                    color: ['ash']
                }
            }
        ]
    },
    [productCodes.FLANNEL_SHEET_SET]: {
        altTag: 'Luxury Flannel Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-close-up',
            'detail-corner'
        ]
    },
    [productCodes.ESSENTIAL_SHEET_SET]: {
        altTag: 'Essential Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [productCodes.SATEEN_COTTON_SHEET_SET]: {
        altTag: 'Signature Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [productCodes.SATEEN_COTTON_PILLOWCASES]: {
        altTag: 'Signature Sateen Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.BANDED_PERCALE_PILLOWCASES]: {
        altTag: 'Banded Percale Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.ORGANIC_COTTON_CHANNEL_QUILT]: {
        altTag: 'Organic Cotton Channel Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['quiltSet', 'color']
            },
            'room-angle',
            'messy',
            'room-front',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'sham-euro',
                attributeSets: ['color'],
                exclusions: {
                    quiltSet: 'quilt-only'
                }
            }
        ]
    },
    [productCodes.ORGANIC_VELVET_QUILT]: {
        altTag: 'Organic Velvet Diamond Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['quiltSet', 'color']
            },
            'room-angle',
            'messy',
            'room-front',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'sham-euro',
                attributeSets: ['color'],
                exclusions: {
                    quiltSet: 'quilt-only'
                }
            }
        ]
    },
    [productCodes.ORGANIC_SATEEN_DUVET_COVER_SET]: {
        altTag: 'Organic Sateen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [productCodes.PERCALE_DUVET_COVER_SET]: {
        altTag: 'Organic Percale Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [productCodes.ESSENTIAL_DUVET_COVER_SET]: {
        altTag: 'Essential Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            {
                descriptorName: 'room-angle',
                attributeSets: ['color'],
                exclusions: {
                    color: 'light-indigo'
                }
            },
            'detail',
            {
                descriptorName: 'lifestyle-detail',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white', 'ash', 'ivory']
                }
            }
        ]
    },
    [productCodes.SIGNATURE_DUVET_COVER_SET]: {
        altTag: 'Signature Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [productCodes.LINEN_DUVET_COVER_SET]: {
        altTag: 'Linen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail',
        ]
    },
    [productCodes.EMBROIDERED_SATEEN_DUVET_SET]: {
        altTag: 'Embroidered Sateen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'room-angle',
            'detail',
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }

            }
        ]
    },
    [productCodes.BANDED_PERCALE_DUVET_SET]: {
        altTag: 'Banded Percale Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'detail',
        ]
    },
    [productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: {
        altTag: 'Graphite Memory Foam Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-close-up',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: {
        altTag: 'High-Density Foam Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-corner',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [productCodes.LATEX_MATTRESS_TOPPER]: {
        altTag: 'Natural Latex Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-corner',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER]: {
        altTag: 'Organic Quilted Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'silo-side',
            'lifestyle-corner'
        ]
    },
    [productCodes.WEIGHTED_SILK_EYE_MASK]: {
        altTag: 'Weighted Silk Eye Mask',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'box-open',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-messy',
                attributeSets: []
            }
        ]
    },
    [productCodes.SILK_EYE_MASK]: {
        altTag: 'Silk Eye Mask',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'lifestyle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['sand', 'graphite']
                }
            },
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-messy',
                attributeSets: []
            }
        ]
    },
    [productCodes.WAFFLE_KNIT_DUVET_COVER_SET]: {
        altTag: 'Waffle Knit Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail'
        ]
    },
    [productCodes.PLUSH_TOWELS]: {
        altTag: 'Plush Towels Collection',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['bathSet', 'color'] // also follows order in array
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: []
            },
            'detail',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.WAFFLE_TOWELS]: {
        altTag: 'Waffle Towel Collection',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['bathSet', 'color']
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: []
            },
            'detail',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [productCodes.PERCALE_PILLOWCASES]: {
        altTag: 'Percale Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.EMBROIDERED_PILLOWCASES]: {
        altTag: 'Embroidered Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle',
            'lifestyle-close-up',
            'lifestyle-angle'
        ]
    },
    [productCodes.LINEN_PILLOWCASES]: {
        altTag: 'Linen Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-detail',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.FLANNEL_PILLOWCASES]: {
        altTag: 'Flannel Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle'
        ]
    },
    [productCodes.ORGANIC_COTTON_PILLOWCASES]: {
        altTag: 'Organic Sateen Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-close-up',
            'lifestyle-angle',
            'lifestyle-profile'
        ]
    },
    [productCodes.VELVET_PICK_STICH_QUILT]: {
        altTag: 'Velvet Pick Stitch Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'detail'
        ]
    },
    [productCodes.SILK_PILLOWCASE]: {
        altTag: 'Silk Pillowcase',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            {
                descriptorName: 'detail',
                attributeSets: []
            },
        ]
    },

    /* ---------  Nightstands --------- */

    [productCodes.ARDEN_NIGHTSTAND]: {
        altTag: 'Arden Nightstand',
        attributeSets: ['color'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [productCodes.HYDE_NIGHTSTAND]: {
        altTag: 'Hyde Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [productCodes.VERDON_NIGHTSTAND]: {
        altTag: 'Verdon Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [productCodes.LEO_NIGHTSTAND]: {
        altTag: 'Leo Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [productCodes.NOIRE_NIGHTSTAND]: {
        altTag: 'Noire Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [productCodes.ATLAS_NIGHTSTAND]: {
        altTag: 'Atlas Nightstand',
        attributeSets: ['color'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    /* ---------  Bundles --------- */

    [productCodes.SATEEN_SHEETS_LATEX_PILLOW]: {
        altTag: 'Sateen Sheet Set & Latex Pillows Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'lifestyle-bed-side-table',
                attributeSets: []
            }
        ]
    },
    [productCodes.SATEEN_SHEETS_LATEX_PILLOW_PAD]: {
        altTag: 'Sateen Sheet Set, Latex Pillows & Mattress Pad Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'lifestyle-bed-side-table',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: []
            }
        ]
    },
    [productCodes.SATEEN_SHEETS_MEMORY_FOAM_PILLOW]: {
        altTag: 'Sateen Sheet Set & Memory Foam Pillows Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'front-single',
                attributeSets: []
            }
        ]
    },
    [productCodes.CLASSIC_STARTER_BUNDLE]: {
        altTag: 'Classic Starter Bundle',
        attributeSets: [],
        imageDescriptors: [
            'room-front-numbered',
            'room-side-numbered',
            'lifestyle',
            'room-above',
            'detail',
            'room-angle',
        ]
    }
}

module.exports = carouselData
