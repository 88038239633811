import React from 'react'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useAttributeOptions } from '@saatva-bits/pattern-library.modules.selection'
import { getArdadImage, transformSwatchOptionsForCarousel } from '@/components/CrossSells/utilities'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

const DetailProductTileSlide = ({ product, tileClasses, useSwatches, hideReviewStars = false, ctaText = null }) => {
    const { getDiscounts } = useGetDiscounts()
    const selectedVariant = useProductState(product.productCode)
    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')

    const swatchOptions = product.options.find(option => option?.code === 'color') 
        || product.options.find(option => option?.code === 'fabric')

    const attributeOptions = useAttributeOptions(product.productCode, swatchOptions?.code)
    const { setAttributeValue } = attributeOptions ?? {}

    const lowestPrice = product.priceRange?.lowestPrice || product.lowestPrice
    const highestPrice = product.priceRange?.highestPrice || product.highestPrice

    const baseProduct = {
        sku: product.sku,
        parentSku: product.parentSku || product.sku,
        category: product.category,
        quantity: 1,
        isPrimaryProduct: true
    }

    const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
    const { finalPrice: highestPriceDiscount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

    // Due to promotion thresholds, the lowestPrice variable can be larger than the highestPrice variable.
    // This is a temporary fix to ensure the price range is displayed correctly.
    const priceRange = {
        fullPriceMin: Math.min(lowestPrice, highestPrice),
        fullPriceMax: Math.max(lowestPrice, highestPrice),
        finalPriceMin: Math.min(lowestPriceDiscount, highestPriceDiscount),
        finalPriceMax: Math.max(lowestPriceDiscount, highestPriceDiscount),
    }

    const swatches = useSwatches && transformSwatchOptionsForCarousel(swatchOptions, setAttributeValue)

    const defaultImage = getArdadImage('product-tile', selectedVariant, product.ardadDescriptors)
    const hoverImage = getArdadImage('product-tile-hover', selectedVariant, product.ardadDescriptors)

    return (
        <DetailProductTile
            containerClassName={tileClasses}
            imageHref={product.url}
            swatches={swatches}
            priceRange={priceRange}
            subtitle={{
                tag: 'h3',
                text: product.subtitle
            }}
            title={{
                href: product.url,
                tag: 'h2',
                text: product.name
            }}
            defaultImage={defaultImage}
            hoverImage={hoverImage}
            reviews={ product.reviews && product.reviews.reviewCount && !hideReviewStars
                ? {
                    rating: product.reviews.averageScore,
                    reviewCount: product.reviews.reviewCount
                } : null
            }
            ctaButton={ ctaText
                ? { text: ctaText, href: product.url }
                : null
            }
            productCode={product.productCode}
            location='PDP Cross Sell Product Tile'
            enableFavorites={isFavoriteIconEnabled}
        />
    )
}

export default DetailProductTileSlide
